import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    FormFeedback,
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";

const UpdateCalendar = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState({
		name: "",
		slug: "",
		sku: "",
		summary: "",
		category: "",
		catalogue: "",
		design: "",
		language: "",
		size: "",
		userQuantity: "",
		description: "",
		paperSize: '',
		paperThickness: '',
		paperSheets: '',
		images: [],
		variations: [{
			quantity: '',
			price: ''
		}]
	});

    const [uniqueId, setUniqueId] = useState(Date.now());
    const [quantity, setQuantity] = useState(1);
    const [file, setFile] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors },
        watch,
        reset,
        setValue,
    } = useForm({
        defaultValues: data,
    });

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + `calendar/${id}`)
            .then((response) => {
                const { data } = response;
                setData(data);
                // Set the form values using setValue method from react-hook-form
                setValue("name", data.name);
                setValue("slug", data.slug);
                setValue("sku", data.sku);
                setValue("summary", data.summary);
                setValue("catalogue", data.catalogue);
                setValue("design", data.design);
                setValue("paperSize", data.paperSize);
                setValue('paperSheets', data.paperSheets);
                setValue('paperThickness', data.paperThickness);
                setValue("language", data.language);
                setValue("description", data.description);
                if (data.variations && Array.isArray(data.variations)) {
                    setValue("variations", data.variations);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }, [id, setValue]);

    const fileChange = (e) => {
        setFile([...file, ...e.target.files]);
    };

    const clearFileState = () => {
        setFile([]);
    };

    const handleValidSubmit = async (data) => {
        const formData = new FormData();
        const values = getValues();

        // for (const [key, value] of Object.entries(values)) {
        // 	formData.append(key, value);
        // }

        // for (const [key, value] of Object.entries(data)) {
        //     if (key === "size") {
        //         if (Array.isArray(value)) {
        //             // If "size" is an array, store its values individually in FormData
        //             for (let i = 0; i < value.length; i++) {
        //                 formData.append("size", value[i]);
        //             }
        //         } else {
        //             // If "size" is not an array, store it as a single value in FormData
        //             formData.append("size", value);
        //         }
        //     } else {
        //         formData.append(key, value);
        //     }
        // }

        for (const [key, value] of Object.entries(data)) {
            if (key === "size") {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        formData.append("size", value[i]);
                    }
                } else {
                    formData.append("size", value);
                }
            } else if (key === "variations" && Array.isArray(value)) {
                // Append the array of objects directly without stringifying
                for (let i = 0; i < value.length; i++) {
                    const variation = value[i];
                    for (const variationKey in variation) {
                        if (Object.prototype.hasOwnProperty.call(variation, variationKey)) {
                            formData.append(`variations[${i}][${variationKey}]`, variation[variationKey]);
                        }
                    }
                }
            } else {
                formData.append(key, value);
            }
        }

        formData.append('imagesToDelete', JSON.stringify(imagesToDelete));

        for (var j = 0; j < file.length; j++) {
            formData.append("images", file[j]);
        }

        console.log(formData, "check form data");
        axios
            .put(process.env.REACT_APP_API_URL + `calendar/update/${id}`, formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            })
            .then((res) => {

                if (res.data.success) {
                    console.log(res.data);
                    toast.success("Product updated successfully");
                    // navigate("/product-list");
                } else {
                    toast.error("An error occurred while updating the product");
                }
            })
            .catch((error) => {
                console.log(error, "Error");
                if (error.response) {
                    // The request was made, but the server responded with a non-2xx status code
                    // You can handle different status codes here
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                    toast.error("Server responded with an error");
                } else if (error.request) {
                    // The request was made, but no response was received
                    console.error(error.request);
                    toast.error("No response received from the server");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error(error.message);
                    toast.error("An error occurred while sending the request");
                }
            });
    };

    const CustomMDEditor = ({ name, control }) => {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={data.description}
                render={({ field }) => (
                    <MDEditor value={field.value} onChange={field.onChange} />
                )}
            />
        );
    };

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        setUniqueId(Date.now());
    }

    const deleteImage = (index) => {
        // Remove image from existing 'data.images' (already uploaded images)
        const updatedImages = data.images.filter((image, i) => i !== index);

        // Track deleted images to send to backend for actual deletion (if needed)
        const updatedImagesToDelete = [...imagesToDelete, data.images[index]];

        setData({ ...data, images: updatedImages });
        setImagesToDelete(updatedImagesToDelete); // Save the deleted image for backend request
    };

    const category = watch("category");
    const language = watch("language");
    const processor = watch("processor");

    const [selectedVariationIndex, setSelectedVariationIndex] = useState(0);
    const [variations, setVariations] = useState([]);

    const addVariation = () => {
        console.log("Adding new variation");
        const newVariation = {
             quantity: "",
            price: "",
        };

        setData((prevData) => {
            const updatedVariations = [...prevData.variations, newVariation];

            return {
                ...prevData,
                variations: updatedVariations,
            };
        });
    };



    const removeVariation = (indexToRemove) => {
        setData((prevData) => {
            const updatedVariations = prevData.variations.filter((_, index) => index !== indexToRemove);

            return {
                ...prevData,
                variations: updatedVariations,
            };
        });
    };

    return (
        <Fragment>
            <Breadcrumb title="Update Calendar" parent="Physical" />
            <ToastContainer />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Update Calendar</h5>
                            </CardHeader>
                            <CardBody>
                                <Row className="product-adding">
                                    <Col xl="12">
                                        <div className="add-product">
                                            <Col xl="3">
                                                <div className="add-product">
                                                    {file && file.length < 6 && (
                                                        <Row>
                                                            <Col xl="12 xl-50" sm="6 col-3">
                                                                <Input
                                                                    key={uniqueId}
                                                                    className="upload"
                                                                    type="file"
                                                                    multiple
                                                                    name="images"
                                                                    accept="image/png, image/gif,image/webp, image/jpeg"
                                                                    onChange={fileChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row style={{ marginTop: "15px" }}>
                                                        {file &&
                                                            file.length > 0 &&
                                                            file.map((item, index) => {
                                                                return (
                                                                    <Col xl="6" sm="3 col-3">
                                                                        <div key={index}>
                                                                            <img
                                                                                style={{ width: "100%" }}
                                                                                src={URL.createObjectURL(item)}
                                                                                alt=""
                                                                            />
                                                                            <Button
                                                                                style={{ marginTop: "10px" }}
                                                                                type="button"
                                                                                color="secondary"
                                                                                size="sm"
                                                                                onClick={() => deleteFile(index)}
                                                                            >
                                                                                delete
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </div>
                                                {data.images && data.images.map((image, index) => (
                                                    <div key={index}>
                                                        <img src={image} alt={`Product Image ${index}`} style={{ width: "200px" }} />
                                                        <Button
                                                            style={{ marginTop: "10px" }}
                                                            type="button"
                                                            color="secondary"
                                                            size="sm"
                                                            onClick={() => deleteImage(index)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                ))}
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <Form
                                            className="needs-validation add-product-form"
                                            onSubmit={handleSubmit(handleValidSubmit)}
                                        >

                                            <div className="form form-label-center">
                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Product Name:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="name"
                                                            control={control}
                                                            defaultValue={data.name}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.name}
                                                                    />
                                                                    {errors.name && (
                                                                        <FormFeedback>{errors.name.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Product Name is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Product URL:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="slug"
                                                            control={control}
                                                            defaultValue={data.slug}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.slug}
                                                                    />
                                                                    {errors.slug && (
                                                                        <FormFeedback>{errors.slug.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Product URL is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        SKU:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="sku"
                                                            control={control}
                                                            defaultValue={data.sku}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.sku}
                                                                    />
                                                                    {errors.sku && (
                                                                        <FormFeedback>{errors.sku.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "SKU is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-6 mb-0">
                                                        Catalogue:
                                                    </Label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <Controller
                                                            name="catalogue"
                                                            control={control}
                                                            defaultValue={data.catalogue}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input type="select" className="form-control" {...field} invalid={!!errors.catalogue}>
                                                                        <option value="">Select Catalogue</option>
                                                                        <option value="glory">Glory</option>
                                                                        <option value="Jeba">Jeba</option>
                                                                        <option value="CPM">CPM</option>
                                                                        <option value="Sharon">Sharon</option>
                                                                        <option value="Olive">Olive</option>
                                                                        <option value="TPP">TPP</option>
                                                                    </Input>
                                                                    {errors.catalogue && (
                                                                        <FormFeedback>{errors.catalogue}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Catalogue is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-6 mb-0">
                                                        Design:
                                                    </Label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <Controller
                                                            name="design"
                                                            control={control}
                                                            defaultValue={data.design}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input type="select" className="form-control" {...field} invalid={!!errors.design}>
                                                                        <option value="">Select Design</option>
                                                                        {/* Generate options from 1 to 100 */}
                                                                        {Array.from({ length: 100 }, (_, index) => (
                                                                            <option key={index + 1} value={`design${index + 1}`}>
                                                                                Design {index + 1}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                    {errors.design && (
                                                                        <FormFeedback>{errors.design}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Design is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>


                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-6 mb-0">
                                                        Language:
                                                    </Label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <Controller
                                                            name="language"
                                                            control={control}
                                                            defaultValue={data.language}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input type="select" className="form-control" {...field} invalid={!!errors.language}>
                                                                        <option value="">Select Language</option>
                                                                        <option value="Tamil">Tamil</option>
                                                                        <option value="English">English</option>
                                                                        <option value="Hindi">Hindi</option>
                                                                        <option value="Telugu">Telugu</option>
                                                                        <option value="Malayalam">Malayalam</option>
                                                                        <option value="Kannada">Kannada</option>
                                                                    </Input>
                                                                    {errors.catalogue && (
                                                                        <FormFeedback>{errors.catalogue}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Catalogue is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Paper Size:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperSize"
															control={control}
															defaultValue={data.paperSize}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperSize}>
																		<option value="">Select Paper Size</option>
																		<option value="15x20">15x20</option>
																		<option value="12x8">18x23</option>
																		<option value="12x8">18x25</option>
																	</Input>
																	{errors.paperSize && (
																		<FormFeedback>{errors.paperSize}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Paper Size is required" }}
														/>
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Paper Thickness:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperThickness" // Update the name based on the selected variation
															control={control}
															defaultValue={data.paperThickness} // Set the default value based on the selected variation
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperThickness}>
																		<option value="">Select Paper Thickness</option>
																		<option value="100gsm">100 gsm</option>
																		<option value="128gsm">128 gsm</option>
																		<option value="157gsm">157 gsm</option>
																		<option value="170gsm">170 gsm</option>
																	</Input>
																	{errors.paperThickness && (
																		<FormFeedback>{errors.paperThickness}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Frame Type is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														No of Sheets:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperSheets" // Update the name based on the selected variation
															control={control}
															defaultValue={data.paperSheets} // Set the default value based on the selected variation
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperSheets}>
																		<option value="">Select Paper Sheeter</option>
																		<option value="3 Sheeter">3 Sheeters</option>
																		<option value="6 Sheeter">6 Sheeters</option>
																	</Input>
																	{errors.paperSheets && (
																		<FormFeedback>{errors.paperSheets}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Paper Sheeter is required" }}
														/>
													</div>
												</FormGroup>


												<div className="board-variaion">
  {data.variations && data.variations.map((variation, index) => (
    <div key={index} className="board-container">
      <FormGroup className="form-group mb-3 row">
        <Label className="col-xl-4 col-sm-6 mb-0">
          Quantity:
        </Label>
        <div className="col-xl-8 col-sm-7">
          <Controller
            name={`variations[${index}].quantity`}
            control={control}
            defaultValue={variation.quantity} // Use variation.quantity directly
            render={({ field }) => (
              <>
                <Input type="select" className="form-control" {...field} invalid={!!errors.variations?.[index]?.quantity} onChange={(e) => { field.onChange(e) }}>
                  <option value="">Select Quantity</option>
                  <option value="50-100">50-100</option>
                  <option value="100-200">100-200</option>
                  <option value="200-300">200-300</option>
                  <option value="300-400">300-400</option>
                  <option value="400-500">400-500</option>
                  <option value="500-600">500-600</option>
                  <option value="600-700">600-700</option>
                  <option value="700-800">700-800</option>
                  <option value="800-900">800-900</option>
                  <option value="900-1000">900-1000</option>
                </Input>
                {errors.variations?.[index]?.quantity && (
                  <FormFeedback>{errors.variations[index].quantity.message}</FormFeedback>
                )}
              </>
            )}
            rules={{ required: "Quantity is required" }}
          />
        </div>
      </FormGroup>

      <FormGroup className="form-group mb-3 row">
        <Label className="col-xl-4 col-sm-6 mb-0">
          Price (per calendar):
        </Label>
        <div className="col-xl-8 col-sm-7">
          <Controller
            name={`variations[${index}].price`}
            control={control}
            defaultValue={variation.price} // Use variation.price directly
            render={({ field }) => (
              <>
                <Input type="text" className="form-control" {...field} invalid={!!errors.variations?.[index]?.price} onChange={(e) => { field.onChange(e) }} />
                {errors.variations?.[index]?.price && (
                  <FormFeedback>{errors.variations[index].price.message}</FormFeedback>
                )}
              </>
            )}
            rules={{ required: "Price is required" }}
          />
        </div>
      </FormGroup>

      {index < 1 && (
        <Button type="button" onClick={addVariation}>
          Add
        </Button>
      )}

      {index > 0 && (
        <Button type="button" onClick={() => removeVariation(index)}>
          Remove
        </Button>
      )}
    </div>
  ))}
</div>


                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4 mb-0">
                                                        Summary:
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Controller
                                                            name="summary"
                                                            control={control}
                                                            defaultValue={data.summary}
                                                            render={({ field }) => (
                                                                <>
                                                                    <Input
                                                                        className="form-control"
                                                                        {...field}
                                                                        type="text"
                                                                        invalid={!!errors.summary}
                                                                    />
                                                                    {errors.summary && (
                                                                        <FormFeedback>{errors.summary.message}</FormFeedback>
                                                                    )}
                                                                </>
                                                            )}
                                                            rules={{ required: "Summary is required" }}
                                                        />
                                                    </div>
                                                </FormGroup>

                                                <FormGroup className="form-group mb-3 row">
                                                    <Label className="col-xl-3 col-sm-4">
                                                        Add Description :
                                                    </Label>
                                                    <div className="col-xl-8 col-sm-7 description-sm">
                                                        <CustomMDEditor name="description" control={control} />
                                                    </div>
                                                </FormGroup>
                                            </div>

                                            <div className="offset-xl-3 offset-sm-4">
                                                <Button type="submit" color="primary">
                                                    Update
                                                </Button>
                                                <Button type="button" color="light">
                                                    Discard
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
};

export default UpdateCalendar;